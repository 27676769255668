import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const renderFeatures = () => {
  let features = [];
  const items = [
    {
      name: "Integrations",
      icon: "plug",
      description: "Integrations with your existing APIs"
    },
    {
      name: "Communication",
      icon: "envelope",
      description: "Built in email communication between business portal and end users"
    },
    {
      name: "Support",
      icon: "headphones",
      description: "We will troubleshoot problems and see them through to resolution"
    }
  ];

  items.forEach((f, i) => {
    features.push(
      <li className="list-item" key={i}>
        <div className="media align-items-center">
          <div className="icon-shape mr-3">
            <div className="shape shape-pipes" />
            <FontAwesomeIcon
              icon={["fas", f.icon]}
              className="icon fa-3x text-alternate"
            />
          </div>

          <div className="media-body">
            <h5 className="bold">{f.name}</h5>
            <p className="my-0">{f.description}</p>
          </div>
        </div>
      </li>
    );
  });

  return features;
};

const WhyUs = () => {
  return (
    <section className="section why-choose-us" id="whyus">
      <div className="shapes-container">
        <div className="pattern pattern-dots" />
      </div>

      <div className="container pb-8 bring-to-front">
        <div className="section-heading text-center">
          <h2 className="heading-line">Why choose us?</h2>
          <p className="text-muted lead mx-auto">
            It’s our attention to the small stuff, building our systems to ensure they are perfect on the customer side as much as they are on the business side. We are creative, while keeping a close eye on the calendar and your budget.


          </p>
        </div>

        <div className="row gap-y">
          <div className="col-md-6">
            <h4 className="bold text-alternate">
              Crossplatform support!
            </h4>
            <p className="text-muted lead mb-5">
              Native, Hybrid, Web and desktop are fully supported.
            </p>

            <ul className="list-unstyled why-icon-list">{renderFeatures()}</ul>

            <hr className="my-5" />
            <div className="text-center text-md-left">
              <Link to="#!" className="btn btn-primary text-contrast">
                Know More
              </Link>
            </div>
          </div>

          <div className="col-md-6">
            <div className="rotated-mockups device-twin">
              <div className="browser absolute shadow-lg">
                <img
                  src={require("assets/img/screens/tablet/1.png")}
                  alt="..."
                />
              </div>

              <div className="front iphone light">
                <div className="screen">
                  <img
                    src={require("assets/img/screens/app/1.png")}
                    alt="..."
                  />
                </div>

                <div className="notch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
