import { initializeApp } from "firebase/app";
import { getFirestore } from '@firebase/firestore'
  
  const firebaseConfig = {
    apiKey: "AIzaSyD5W5GOfpdxNlIkaQu6MZriiB5OJ_t82N0",
    authDomain: "internal-4a765.firebaseapp.com",
    projectId: "internal-4a765",
    storageBucket: "internal-4a765.appspot.com",
    messagingSenderId: "245667508202",
    appId: "1:245667508202:web:273a3776850e9feff38c93",
    measurementId: "G-ED0905CC6M"
  }
  
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
  
export default db;