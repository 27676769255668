import React from 'react';
import YouTube from 'react-youtube';

class Video extends React.Component {

  render() {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const width = vw < 800 ? (vw-25) : (vw/2-50)
    const height = width *9/16 ;
    console.log('vw = ', vw)
    const opts = {
      width: width,
      height: height,
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,

      },
    };

    return <YouTube videoId="gfjNIeFgWYw" opts={opts} onReady={this._onReady} />;
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}

export default Video