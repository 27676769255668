import React from "react";
import { Container, Row, Col } from "reactstrap";
import PowerfulCard from "./PowerfulCard.jsx";
import MoreInfoButton from "components/MoreInfoButton.jsx";

const createCards = use => {
  let features = [];
  let cards = [
    {
      class: "mt-6 mt-6 mx-lg-auto",
      icon: "paint-bucket",
      title: "Customizable",
      animation: { delay: 0 },
      description: " Visuals or Functionality. Make it yours by tweaking it to your prefereces."
    },

    {
      class: "mx-lg-auto",
      icon: "light",
      title: "Intuitive",
      animation: { delay: 800 },
      description: "Easily find your way around our products with/without documentation"
    },

    {
      class: "mr-lg-auto",
      icon: "diamond",
      title: "Interactive",
      animation: { delay: 500 },
      description: "Unobtrusive and subtle animations built to direct your focus on actionable items"
    },

    {
      class: "mt-6n mr-lg-auto",
      icon: "lock",
      title: "Security",
      animation: { delay: 1200 },
      description: "Your data is protected from unauthorized access throughout its lifecycle."
    }
  ];

  cards.forEach((el, i) => {
    if (i % 2 === use) {
      features.push(<PowerfulCard {...el} key={i} />);
    }
  });

  return features;
};

const PowerfulFeatures = () => {
  return (
    <section className="section alter3-features" id="features">
      <div className="shapes-container">
        <div className="shape shape-ring shape-ring-1">
          <div className="animation animation--rotating" />
        </div>
        <div className="shape shape-ring shape-ring-2">
          <div className="animation animation--rotating" />
        </div>
        <div className="shape shape-circle animation--clockwise">
          <div />
        </div>

        <div className="shape background-shape-main" />
      </div>

      <Container>
        <Row>
          <Col lg="5" className="order-lg-last">
            <div className="section-heading">
              <p className="px-2 text-alternate text-uppercase bold">
                Robust features engineered to be flexible
              </p>
              <h2 className="heading-line">
                World class quality software
              </h2>

              <p className="lead text-muted my-4 semi-bold">
                The best blend of form and functionality.
              </p>

              <p>
              We build our system to be operationally efficient and this is done ensuring development of quality product from the look and feel to the security of our systems.{" "}
              </p>

              <MoreInfoButton
                className="btn btn-primary text-contrast bold"
                text="Who are we?"
                to="#!"
              />
            </div>
          </Col>

          <Col lg="7" className="pr-lg-6">
            <Row>
              <Col lg="6" className="rotated-cards">
                {createCards(0)}
              </Col>
              <Col lg="6" className="rotated-cards">
                {createCards(1)}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PowerfulFeatures;
