import React, { useState } from "react";
import { Container, Row, Col, Nav, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactUs from "views/modals/contact.jsx";
import Button from "reactstrap/lib/Button";

const Heading = () => {

  const [showContactUs, setShowContactUs] = useState(false);

  return (
    <header className="header alter3-header section gradient gradient-primary-auxiliary text-contrast" id="hero">
      <ContactUs show={showContactUs} onHideForm={()=>{
        setShowContactUs(false)
      }}/>
      <div className="shapes-container">
        <div className="animation-shape shape-ring animation--rotating-diagonal">
          <div />
        </div>
        <div className="animation-shape shape-circle animation--clockwise">
          <div />
        </div>
        <div className="animation-shape shape-triangle animation--anti-clockwise">
          <div className="animation--rotating" />
        </div>
        <div className="animation-shape shape-diamond animation--anti-clockwise">
          <div className="animation--rotating" />
        </div>

        <div className="static-shape shape-ring-1" />
        <div className="static-shape shape-ring-2" />
        <div className="static-shape shape-circle-1" />

        <div className="static-shape pattern-dots-1" />
        <div className="static-shape pattern-dots-2" />

        <div className="static-shape ghost-shape ghost-shape-1" />
      </div>

      <Container className="bring-to-front">
        <Row>
          <Col md="6">
            <h6 className="bold" style={{fontSize: '3rem'}} id="hero-title">
              Innovate･ium
              <span className="d-block light mini">
                Element of spectacular change.
              </span>
            </h6>
            <p className="lead">
              We are helping businesses to fully embrace the digital age by developing innovative apps that will help them migrate to fully automated solutions.
            </p>

            <Nav className="mt-5" tag="nav">
              <Button
                onClick={()=>{
                  setShowContactUs(true);
                }}
                className="mr-3 btn btn btn-rounded btn-contrast"
              >
                <FontAwesomeIcon icon={["fas", "envelope"]} className="mr-3" />
                Contact Us
              </Button>
              {/* <NavLink href="#partners" className="btn btn-rounded btn-outline-contrast">
                Next
              </NavLink> */}
            </Nav>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Heading;
