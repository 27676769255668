import React from "react";
import classNames from "classnames/bind";
import { Container, Row, Col } from "reactstrap";
import MoreInfoButton from "components/MoreInfoButton";
import Tilt from "react-tilt";
import Fade from "react-reveal/Fade";
import Video from "./Video.jsx";

const renderItems = () => {
  let items = [];

  for (let i = 1; i < 4; i++) {
    items.push(
      <Tilt options={{ max: 20, glare: true, maxGlare: 0.4 }} key={i}>
        <div
          className={classNames(
            "rounded tilt overflow-hidden shadow-box shadow-hover bg-contrast",
            { "mt-5": i > 1 }
          )}
        >
          <figure>
            <img
              src={require(`assets/img/screens/app/pieces/${i}.png`)}
              className="img-responsive"
              alt=""
            />
          </figure>
        </div>
      </Tilt>
    );
  }

  return items;
};

const Journey = () => {
  return (
    <section className="section powered-design" id="journey">
      <div className="shapes-container">
        <div className="shape shape-circle shape-circle-1">
          <Fade bottom right duration={1500}>
            <div />
          </Fade>
        </div>
        <div className="shape shape-circle shape-circle-2">
          <Fade bottom right duration={1200} delay={500}>
            <div />
          </Fade>
        </div>
        <div className="shape shape-ring animation--rotating-diagonal">
          <div />
        </div>
        <div className="shape shape-triangle shape-animated">
          <div className="animation--rotating" />
        </div>

        <div className="shape pattern-dots-1" />
      </div>

      <Container>
        <Row className="gap-y align-items-center">
          <Col md="12" sm='12' lg='5'>
            <div className="section-heading">
              <h2 className="heading-line">
                Our Journey
              </h2>
              <p className="lead text-muted">
                We've been a part of different hackathon challenges across various sectors of the industry. Each of these challenges have seasoned us to be proffesionals we are today.
              </p>
            </div>

            <MoreInfoButton
              className="btn btn-outline-alternate more-link mt-0"
              text="You got my attention"
              to="#!"
            />
          </Col>

          <Col md="12" sm='12' lg='7'>
            <Video/>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Journey;
