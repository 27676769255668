import React from "react";
import { Container, Row, Col, Nav, NavItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoreInfoButton from "components/MoreInfoButton";

const renderPartners = () => {
  let partners = [];

  for (let i = 2; i <= 6; ++i) {
    partners.push(
      <NavItem className="py-2 op-5 d-flex row" key={i}>
        <Col>
          <img
            src={require(`assets/img/logos/${i}.png`)}
            className="icon"
            style={{height: 50, objectFit: 'contain'}}
            alt=""
          />
        </Col>
      </NavItem>
    );
  }

  return partners;
};

const Partners = () => {
  return (
    <section className="section alter3-partners bg-contrast edge top-left" id="pa">
      <Container className="bring-to-front pb-md-9">
        <Row>
          <Col md="6">
            <div className="section-heading">
              <FontAwesomeIcon
                icon={["fas", "handshake"]}
                className="fa-3x accent mb-3"
              />

              <h3 className="text-alternate heading-line">
                We've worked with these awesome organisations
              </h3>

              <p className="lead">
                Since our inception we've worked with various oraganisations ranging from government and private cooporations to develop ground breaking solutions aimed at digitizing daily business processes.
              </p>
            </div>

            <Nav>
              {renderPartners()}

            </Nav>

            <MoreInfoButton
              className="btn btn-outline-primary bold"
              text="Learn more"
              to="#features"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Partners;
