import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Form, FormGroup, Label, Input, Col, Row } from "reactstrap";
import React, { useState, useEffect } from "react"
import db from '../../firebase/firebase'
import { collection, addDoc } from 'firebase/firestore'
import FormFeedback from "reactstrap/lib/FormFeedback";
import Done from './done'

const ContactUs = ({show, onHideForm}) => {

    const [data, setData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    })
    const [errors, setErrors] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [hasInitiatedSubmit, setHasInitiatedSubmit] = useState(false)
    const messagesCollection = collection(db, 'messages')
    const [isDone, setIsDone] = useState(false)

    const submit = (e) => {

        e.preventDefault()

        setHasInitiatedSubmit(true)
        setIsSubmit(true)

        setErrors(checkErrors(data))
    };

    const checkErrors = (values) => {

        const errs = {}

        if (!values.name){

            errs.name = 'Name is required'
        }

        //eslint-disable-next-line
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if (!values.email){

            errs.email = 'Email is required'
        } else if (!emailRegex.test(values.email)){

            errs.email = 'Invalid email address'
        }

        if (!values.message){

            errs.message = 'Message is required'
        }

        if (!values.subject){

            errs.subject = 'Subject is required'
        }

        return errs;
    };

    const reset = () => {

        setHasInitiatedSubmit(false);
        setIsSubmit(false)
        setData({
            name: '',
            email: '',
            subject: '',
            message: ''
        })
        setIsDone(false)

        setErrors({})
    }

    const handleChange = (e) => {

        const { name, value } = e.target

        setData(prevData=>({...prevData, [name]: value}))
    }

    useEffect(() => {
        
        if (Object.keys(errors).length === 0 && isSubmit){

            try {

                addDoc(messagesCollection, data).then((docRef)=>{
    
                    // console.log("Document written with ID: ", docRef.id);
                });
                setIsDone(true)
    
            } catch (e) {

                // console.error("Error adding document: ", e);
            }
        } else if (Object.keys(errors).length !== 0) {

            // console.log('Errors are: ' + JSON.stringify(errors))
        }
    }, [errors]);

    return (
        <div>
        
        <Modal isOpen={show} scrollable>
            <ModalHeader>
            Contact Us
            </ModalHeader>
            { isDone ? <ModalBody>
                <h5 className="w-100, text-center">Message Sent</h5>
                <ModalFooter>

                <Button                            
                    color="primary"
                    onClick={()=>{
                        onHideForm();
                        reset();
                        }}>
                    Done
                </Button>
                </ModalFooter>
            </ModalBody> : <ModalBody>
            <Form onSubmit={(e)=>submit(e)}>

            <Row form>
                <Col>
                    <FormGroup>
                    <Label for="exampleName">
                        Name
                    </Label>
                    <Input
                        id="exampleName"
                        name="name"
                        placeholder="Onthatile Washe"
                        type="name"
                        value={data.name}
                        onChange={handleChange}
                        invalid={((errors.name || "").length > 0 && hasInitiatedSubmit)}
                        />
                        <FormFeedback>
                            {errors.name || "" + ` = ${errors.name !== null}, ${hasInitiatedSubmit}`}
                        </FormFeedback>
                    </FormGroup>
                </Col>
                </Row>
                <Row form>
                <Col>
                    <FormGroup>
                    <Label for="exampleEmail">
                        Email
                    </Label>
                    <Input
                        id="exampleEmail"
                        name="email"
                        placeholder="washe@innovateium.co.bw"
                        type="email"
                        value={data.email}
                        onChange={handleChange}
                        invalid={((errors.email || "").length > 0 && hasInitiatedSubmit)}
                        />
                        <FormFeedback>
                            {errors.email}
                        </FormFeedback>
                    </FormGroup>
                </Col>
                </Row>
                <Row form>
                    <Col>
                        <FormGroup>
                            <Label for="exampleSubject">
                                Subject
                            </Label>
                            <Input
                                id="subject"
                                name="subject"
                                placeholder="A Subject"
                                type="text"
                                value={data.subject}
                                onChange={handleChange}
                                invalid={((errors.subject || "").length > 0 && hasInitiatedSubmit)}
                                />
                                <FormFeedback>
                                    {errors.subject}
                                </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                                <Label for="exampleMessage">
                                Message
                                </Label>
                                <Input
                                    id="message"
                                    name="message"
                                    placeholder="Some message"
                                    type="textarea"
                                    value={data.message}
                                    onChange={handleChange}
                                    invalid={((errors.message || "").length > 0 && hasInitiatedSubmit)}
                                    />
                                    <FormFeedback>
                                        {errors.message}
                                    </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>

            <ModalFooter>

            <Button                            
                color="primary"
                onClick={()=>onHideForm()}>
                Cancel
            </Button>
            <Button type='submit'>
                    Send
            </Button>
            </ModalFooter>
            </Form>
            </ModalBody>}
        </Modal>
        </div>
    );
  };
  
  export default ContactUs;