import React from "react";

// shared page components
import PowerfulFeatures from "../shared/PowerfulFeatures.jsx";
import Journey from "../shared/Journey.jsx";
import Ecommerce from "../shared/Ecommerce.jsx";
import WhyUs from "../shared/WhyUs.jsx";
import Subscribe from "../shared/Subscribe.jsx";

// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";

// alternative 3 page components
import Heading from "../alter3/Heading.jsx";
import IsometricMockups from "../alter3/IsometricMockups.jsx";
import Partners from "../alter3/Partners.jsx";

class Alternative3 extends React.Component {
  render() {
    return (
      <>
        <DefaultNavbar/>
        <main ref="main" style={{overflow:'hidden', maxWidth: '100%'}}>
          {/* Alternative 3 specific components */}
          <Heading />
          <IsometricMockups />
          <Partners />
          <Ecommerce/>

          {/* Shared Components */}
          <PowerfulFeatures />
          <Journey />
          <WhyUs />
          <Subscribe />
        </main>
        <DefaultFooter />
      </>
    );
  }
}

export default Alternative3;
